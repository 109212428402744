import loadable from '@loadable/component'
import layoutStyles from "../../Layout/Layout.module.scss";
import {useTranslation} from "react-i18next";

const ContactForm = loadable(() => import('../../ContactForm/ContactForm'));
const BusinessMap = loadable(() => import('../../Map/BusinessMap'));

const Contact = () => {
  const { t } = useTranslation()
  return (
    <section className={layoutStyles.HomeSection} id="section-contact">
      <div className={layoutStyles.HomeSectionLeft}>
        <div className={layoutStyles.ContainerHalf}>
          <div style={{padding: '2rem 2rem 0'}}>
            <h2 style={{color: '#fff'}}>{t('Formularz kontaktowy')}</h2>
          </div>
          <ContactForm />
        </div>
      </div>
      <div className={layoutStyles.HomeSectionRight}>
        <BusinessMap/>
      </div>
    </section>


  )
}

export default Contact;
